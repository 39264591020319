<script>
export default [
    {
        text:"Taxable",
        value:"taxable"
    },
    {
        text:"Debours",
        value:"debours"
    },
]
</script>