<script>
export default [
    {
        text:"Litre",
        value:"litre"
    },
    {
        text:"Pcs",
        value:"pcs"
    },
    {
        text:"Gramme",
        value:"gramme"
    },
    {
        text:"Kilogramme",
        value:"kilogramme"
    },
]
</script>